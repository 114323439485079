import React, { useState, useEffect } from 'react';

interface MinecraftStatusResponse {
  status: string; // Adjust this according to your actual response structure
  PublicIpAddress: string; // Adjust this according to your actual response structure
}

const MinecraftStatusComponent = () => {
  const [response, setResponse] = useState<MinecraftStatusResponse | null>(null);

  useEffect(() => {
    fetchMinecraftStatus(); // Fetch status once when component mounts
  }, []); // Empty dependency array ensures it only runs once when component mounts

  const fetchMinecraftStatus = async () => {
    try {
      const response = await fetch('https://mop19ui4j2.execute-api.us-east-1.amazonaws.com/prod/minecraft', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "type": "status" })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch');
      }

      const data: MinecraftStatusResponse = await response.json();
      setResponse(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error state if needed
    }
  };

  const handleClick = () => {
    fetchMinecraftStatus(); // Fetch status again when button is clicked
  };

  return (
    <div>
      <button onClick={handleClick}>Fetch Minecraft Status</button>
      {response ? (
        <div className="card">
          <h3>Minecraft Server Status</h3>
          <p>Status: {response.status}</p>
          <p>IP: {response.PublicIpAddress}</p>
          {/* Add more details from the response as needed */}
        </div>
      ) : (
        <p>Loading Minecraft status...</p>
      )}
    </div>
  );
};

export default MinecraftStatusComponent;
