import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MinecraftControlComponent = () => {
  const [serverStatus, setServerStatus] = useState('off'); // Initialize state as 'off'

  useEffect(() => {
    fetchMinecraftStatus(); // Fetch initial Minecraft server status when component mounts
  }, []);

  const fetchMinecraftStatus = async () => {
    try {
      const apiUrl = 'https://mop19ui4j2.execute-api.us-east-1.amazonaws.com/prod/minecraft';
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "type": "status" })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch server status');
      }

      // Assuming the response status indicates the server status
      const status = await response.json(); // Get the status from the response
      if (status.status.toLowerCase() === 'pending' || status.status.toLowerCase() === 'running') {
        setServerStatus('on');
      } else if (status.status.toLowerCase() === 'stopping' || status.status.toLowerCase() === 'stopped') {
        setServerStatus('off');
      } else {
        console.warn('Unexpected server status:', status);
        // Handle unexpected server status if needed
      }

    } catch (error) {
      console.error('Error fetching Minecraft status:', error);
      // Handle error state if needed
    }
  };

  const handleToggle = async () => {
    try {
      const newStatus = serverStatus === 'on' ? 'off' : 'on';
      setServerStatus(newStatus);

      const apiUrl = 'https://mop19ui4j2.execute-api.us-east-1.amazonaws.com/prod/minecraft';
      const payload = {
        type: newStatus === 'on' ? 'turn-on' : 'turn-off'
      };

      // Make POST request using Axios
      const response = await axios.post(apiUrl, payload);
      console.log('Server control response:', response.data);

    } catch (error) {
      console.error('Error controlling server:', error);
      // Handle error state if needed
    }
  };

  return (
    <div>
      <h3>Minecraft Server Control</h3>
      <p>Server is currently: {serverStatus}</p>
      <button onClick={handleToggle}>
        {serverStatus === 'on' ? 'Turn Off' : 'Turn On'}
      </button>
    </div>
  );
};

export default MinecraftControlComponent;
