import React from 'react';
import '../css/Home.css';
import MinecraftStatusComponent from '../components/Status';
import MinecraftControlComponent from '../components/Turn'; // Adjust the path based on your file structure


const Home = () => {
  return (
    <div className="Home">
      <MinecraftStatusComponent />
      <MinecraftControlComponent />
    </div>
  );
};

export default Home;
